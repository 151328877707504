import { useInfiniteQuery } from "vue-query";

import { reactive, ref } from '@vue/reactivity'
// import httpClient from "@/http/httpClient";
// import store from "@/store";
import { httpReq } from "@/htttpReq";


const fetchData = async ({ pageParam = 1, queryKey }, queryParams) => {
  const { sort, search,paginate, ['expiry date']: expiryDate, ...restOfTheQuery } = queryParams;
  let paginateQuery = paginate ? { paginate } : { paginate: 1000 }; //Mandatory
  const sortQuery = {
    sort: "name",
  };

  if (sort) {
    const { [0]: sortName, [1]: direction } = sort.toString().split("|");
    sortQuery.sort = direction === "asc" ? sortName : `-${sortName}`;
  }


  const response = await httpReq.get(`ordering-portal/account-information-maintenance/price-lists`, {
    ...restOfTheQuery, 
    ...sortQuery,
    ...(search? {'filter[search]': search}: {}),
    ...paginateQuery,
    ...{ page: pageParam }
  });
  return {
    data: response.data.data,
    pagination: response.data.pagination,
  }
};

export default function useGetPricelists(queryParams = ref({})) {
  console.log('params..', queryParams.value)
  const queryKey = reactive(["price-lists", { queryParams }]);
  return useInfiniteQuery(
    queryKey,
    (params) => fetchData(params, queryParams.value),
    {
      getNextPageParam: (lastPage, pages) => {
        const { pagination } = lastPage;
        return !pagination.has_more_pages
          ? undefined
          : Number(pagination.current_page) + 1;
      },
      refetchOnWindowFocus: false,
      // keepPreviousData: true,
      //   placeholderData: [],
        staleTime: Infinity,
      onError: (error) => {
        // store.commit("errorsModal/openModal", error.response.data.errors);
      },
    }
  );
}
